import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpointService } from '../../../domain/services/breakpointService';
import { isElementVisible } from '../../../domain/utils/domUtils';
import { useWindowSize } from '../../../hooks/useWindowSize';

const styles = {
  container: css`
    position: relative;
  `
};

const retrieveSlidesStatus = slidesSet => {
  const slides = slidesSet;
  slides.lastActive = slides.active;
  slides.active = slides.items.find(slide =>
    isElementVisible(slide.domElement)
  );
  slides.lastBelow = slides.below;
  if (slides.active) {
    slides.below = slides.items[slides.active.delta + 1];
  }
};

export const VerticalSlideShow = ({ children }) => {
  // Keep a local reference to the slides object, instead of sharing a global object
  // that would lead to problems with the transporter or when navigating from a
  // vertical slideshow to another.
  const slides = useRef({
    items: [],
    active: undefined,
    lastActive: undefined,
    below: undefined,
    lastBelow: undefined
  });
  const containerTag = useRef(null);

  const setSlidesLayout = () => {
    // Retrieve the current status of slides.
    retrieveSlidesStatus(slides.current);

    // Avoid updating layout if nothing has changed.
    if (
      slides.current.active === slides.current.lastActive ||
      slides.current.below === slides.current.lastBelow
    ) {
      return;
    }

    requestAnimationFrame(() => {
      if (slides.current.active?.mediaWrapper) {
        // Active slide.
        Object.assign(slides.current.active.mediaWrapper.style, {
          position: 'sticky',
          top: '0'
        });
      }
      if (slides.current.active.contentBox) {
        Object.assign(slides.current.active.contentBox.style, {
          position: 'relative',
          // Keep these values in sync with VerticalSlideContentBox --> styles.contentBox
          margin: '-150px 0 0 140px',
          top: 'auto',
          left: 'auto'
        });
      }

      if (slides.current.below?.mediaWrapper) {
        Object.assign(slides.current.below.mediaWrapper.style, {
          position: 'fixed',
          top: '0'
        });
      }
      if (slides.current.below?.contentBox) {
        Object.assign(slides.current.below.contentBox.style, {
          position: 'fixed',
          // Keep these values in sync with VerticalSlideContentBox --> styles.contentBox
          margin: '0',
          top: 'calc(100vh - 150px)',
          left: '140px'
        });
      }
    });
  };

  const resetSlidesLayout = () => {
    // Retrieve the current status of slides.
    retrieveSlidesStatus(slides.current);

    slides.current.items.forEach(slide => {
      const slideElement = slide;
      slideElement.domElement.style.height = 'auto';
      if (slideElement.mediaWrapper) {
        Object.assign(slideElement.mediaWrapper.style, {
          position: 'static',
          top: 'auto'
        });
      }
      if (slideElement.contentBox) {
        Object.assign(slideElement.contentBox.style, {
          position: 'relative',
          margin: 0,
          top: 'auto',
          left: 'auto'
        });
      }
    });
  };

  const isValidBreakpoint = () =>
    document.documentElement.clientWidth >=
    breakpointService.getTabletBreakpoint().width;

  const windowSize = useWindowSize({ debounceWait: 100 });

  useLayoutEffect(() => {
    // Initialize the set of slides, so we don't need to search the DOM again.
    slides.current.items = [];
    containerTag.current
      .querySelectorAll('section[data-vertical-slideshow-item="true"]')
      .forEach((slide, delta) => {
        slides.current.items.push({
          delta,
          domElement: slide,
          mediaWrapper: slide.querySelector(
            'div[data-vertical-slideshow-media-wrapper="true"]'
          ),
          contentBox: slide.querySelector(
            'div[data-vertical-slideshow-content-box="true"]'
          )
        });
        // Explicitly set the height of slides, so it doesn't change when the DOM is manipulated.
        if (isValidBreakpoint()) {
          const slideElement = slide;
          if (delta === 0) {
            slideElement.style.height = `100vh`;
          } else {
            const slideHeight =
              slides.current.items[delta].mediaWrapper?.offsetHeight +
              slides.current.items[delta].contentBox?.offsetHeight -
              150;
            slideElement.style.height = `${slideHeight}px`;
          }
        }
      });

    if (isValidBreakpoint()) {
      setSlidesLayout();
      window.addEventListener('scroll', setSlidesLayout);
    } else {
      resetSlidesLayout();
    }
    return () => {
      window.removeEventListener('scroll', setSlidesLayout);
    };
  }, [windowSize.width, windowSize.height]);

  return (
    <div ref={containerTag} css={styles.container}>
      {children}
    </div>
  );
};

VerticalSlideShow.propTypes = {
  children: PropTypes.node.isRequired
};
