import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { Ad } from '../atoms/Ad';
import { PhotographersLink } from '../atoms/PhotographersLink';

const styles = {
  contentBox: css`
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    ${theme.font.family('regularWeb')};
    font-weight: ${theme.font.weight('base')};
    line-height: ${theme.line.height('l')};
    min-height: 150px;
    padding: ${theme.spacing.parse('0 $xs')};
    width: auto;
    ${theme.mq.small_desktop} {
      padding: ${theme.spacing('xs')};
      width: 400px;
      /* Keep these values in sync with VerticalSlideShow */
      margin: -150px 0 0 140px;
    }
    a {
      color: #fff;
      text-decoration: underline;
      text-decoration-color: #fc0;
    }
  `,
  title: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('l')};
    ${theme.mq.small_desktop} {
      font-size: ${theme.font.size('xl')};
    }
    font-weight: ${theme.font.weight('l')};
    letter-spacing: ${theme.letterSpacing('base')};
    margin: ${theme.spacing.parse('$s 0 auto')};
    text-transform: uppercase;
  `,
  subtitle: css`
    font-size: ${theme.font.size('base')};
    ${theme.mq.small_desktop} {
      font-size: ${theme.font.size('l')};
    }
    font-weight: ${theme.font.weight('l')};
    letter-spacing: ${theme.letterSpacing('s')};
    margin-bottom: ${theme.spacing('xs2')};
  `,
  photoCaption: css`
    font-size: ${theme.font.size('s')};
    ${theme.mq.small_desktop} {
      font-size: ${theme.font.size('base')};
    }
    letter-spacing: ${theme.letterSpacing('s')};
  `,
  sponsoredText: css`
    font-size: ${theme.font.size('s')};
    margin: ${theme.spacing.parse('0 0 $s')};
    text-transform: uppercase;
    letter-spacing: ${theme.letterSpacing('base')};
  `,
  photoCredit: css`
    color: silver;
    font-size: ${theme.font.size('xs')};
    letter-spacing: ${theme.letterSpacing('base')};
    margin: ${theme.spacing.parse('$s 0')};
    text-transform: uppercase;
    a {
      text-decoration: none;
      color: silver;
    }
  `,
  slideNumber: css`
    display: inline;
    font-size: ${theme.font.size('xl5')};
    font-weight: ${theme.font.weight('base')};
    letter-spacing: ${theme.letterSpacing('s')};
    margin-right: ${theme.spacing('s')};
    text-transform: uppercase;
  `,
  slideNumberWrapper: css`
    display: flex;
    margin-bottom: ${theme.spacing('xs')};
  `
};

export const VerticalSlideContentBox = ({ data, slideNumber, content }) => {
  return (
    <div css={styles.contentBox} data-vertical-slideshow-content-box>
      <div css={styles.slideNumberWrapper}>
        {content.showSlidesNumbers && (
          <div css={styles.slideNumber}>{`0${slideNumber}`.slice(-2)}</div>
        )}
        {data.title && (
          <h2
            css={styles.title}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        )}
      </div>
      {data.subtitle && (
        <p
          css={styles.subtitle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data.subtitle }}
        />
      )}
      {data.photoCaption && (
        <div
          css={styles.photoCaption}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data.photoCaption.replaceAll('\r\n', '&nbsp;<br/>')
          }}
        />
      )}
      {!data.photoCaption && data.slideImage?.entity?.caption && (
        <div
          css={styles.photoCaption}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data.slideImage?.entity?.caption.replaceAll(
              '\r\n',
              '&nbsp;<br/>'
            )
          }}
        />
      )}
      {data.showAdBanner && (
        <Ad id={`vertical_slideshow__${slideNumber}`} content={content} />
      )}
      {data.photoCredit && (
        <p
          css={styles.photoCredit}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data.photoCredit.replaceAll('\r\n', '&nbsp;<br/>')
          }}
        />
      )}
      {!data.photoCredit && data.slideImage?.entity?.photographers?.[0] && (
        <p css={styles.photoCredit}>
          <PhotographersLink
            color={{ text: 'inherit', link: 'inherit' }}
            photographers={data.slideImage.entity.photographers}
          />
        </p>
      )}
    </div>
  );
};

VerticalSlideContentBox.defaultProps = {
  content: undefined
};

VerticalSlideContentBox.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  slideNumber: PropTypes.number.isRequired,
  content: PropTypes.objectOf(PropTypes.any)
};
