import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { dateService } from '../../../domain/services/dateService';
import { SponsoredImage } from '../../SponsoredImage';
import { PhotographersLink } from '../atoms/PhotographersLink';

export const VerticalSlideLeadBox = ({ data, content }) => {
  const hasLogo = !!content.sponsoredLogo?.entity?.mediaImage?.url;

  const isWithinDateRange = dateService.isWithinDateRange(
    Math.floor(new Date().getTime() / 1000),
    content.sponsoredStartDate?.timestamp,
    content.sponsoredEndDate?.timestamp
  );

  const styles = {
    container: css`
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      display: grid;
      align-content: center;
    `,
    leadBox: css`
      color: #fff;
      ${theme.font.family('regularWeb')};
      font-weight: ${theme.font.weight('base')};
      max-width: 800px;
      margin: 0 auto 100px auto;
      min-height: 150px;
      padding: ${theme.spacing.parse('0 $xs')};
      text-align: center;
      width: auto;
      a {
        color: #fff;
        text-decoration: underline;
        text-decoration-color: #fc0;
      }
    `,
    sponsoredText: css`
      font-size: ${theme.font.size('s')};
      margin: ${theme.spacing.parse('0 0 $xs2')};
      text-transform: uppercase;
      letter-spacing: ${theme.letterSpacing('base')};
      text-shadow: 0 0 10px #000;
    `,
    title: css`
      ${theme.font.family('boldWeb')};
      font-size: ${theme.font.size('xl2')};
      letter-spacing: ${theme.letterSpacing('base')};
      line-height: ${theme.line.height('s')};
      margin: ${theme.spacing.parse('$xs 0')};
      text-transform: uppercase;
      text-shadow: 0 0 10px #000;
      ${theme.mq.tablet} {
        font-size: ${theme.font.size('xl4')};
      }
    `,
    subtitle: css`
      font-size: ${theme.font.size('base')};
      line-height: ${theme.line.height('base')};
      text-shadow: 0 0 10px #000;
      ${theme.mq.tablet} {
        font-size: ${theme.font.size('xl')};
      }
      margin: ${theme.spacing.parse('$s 0')};
    `,
    photoCaption: css`
      font-size: ${theme.font.size('s')};
      line-height: ${theme.line.height('base')};
      text-shadow: 0 0 10px #000;
      ${theme.mq.small_desktop} {
        font-size: ${theme.font.size('base')};
      }
      letter-spacing: ${theme.letterSpacing('s')};
      margin: ${theme.spacing.parse('$s 0')};
    `,
    wordsBy: css`
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('s')};
      letter-spacing: ${theme.letterSpacing('base')};
      text-transform: uppercase;
      text-shadow: 0 0 10px #000;
    `,
    photoCredit: css`
      ${theme.font.family('regularWeb')};
      color: silver;
      font-size: ${theme.font.size('xs')};
      font-weight: ${theme.font.weight('l')};
      letter-spacing: ${theme.letterSpacing('base')};
      text-transform: uppercase;
      position: absolute;
      bottom: ${theme.spacing('s')};
      left: ${theme.spacing('s')};
      text-shadow: 0 0 10px #000;
    `
  };

  return (
    <div css={styles.container}>
      <div css={styles.leadBox}>
        {content.partnerHeadline && isWithinDateRange && (
          <p css={styles.sponsoredText}>{content.partnerHeadline}</p>
        )}
        {hasLogo && isWithinDateRange && <SponsoredImage content={content} />}
        <h1 css={styles.title}>{data.title || content.title}</h1>
        {data.subtitle && (
          <p
            css={styles.subtitle}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: data.subtitle }}
          />
        )}
        {data.photoCaption && (
          <p
            css={styles.photoCaption}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: data.photoCaption.replaceAll('\r\n', '<br/>')
            }}
          />
        )}
        {content.wordsByAuthor && (
          <p
            css={styles.wordsBy}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: content.wordsByAuthor.replaceAll('\r\n', '<br/>')
            }}
          />
        )}
      </div>
      {data.photoCredit && (
        <p
          css={styles.photoCredit}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data.photoCredit.replaceAll('\r\n', '<br/>')
          }}
        />
      )}
      {!data.photoCredit && data.slideImage?.entity?.photographers?.[0] && (
        <p css={styles.photoCredit}>
          <PhotographersLink
            color={{ text: 'inherit', link: 'inherit' }}
            photographers={data.slideImage.entity.photographers}
          />
        </p>
      )}
    </div>
  );
};

VerticalSlideLeadBox.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
