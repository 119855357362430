import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { AmbientPlayer } from '../../AmbientPlayer';

export const VerticalSlideItem = ({ data, delta, lead, children }) => {
  const hasImage = !!data.slideImage?.entity?.mediaImage?.url;
  const hasVideo = !!data.slideVideo?.entity;

  const backgroundImage = hasImage
    ? data.slideImage.entity.mediaImage.url
    : null;
  const backgroundImageUrls = backgroundImage
    ? imageResizerService.getStyleUrlsByBreakpoint(backgroundImage, {
        position: data.slideImage?.entity.focalPoint
      })
    : null;

  const opacity = css`
    opacity: ${data.mediaTint ? (100 - data.mediaTint) / 100 : 1};
  `;

  const backgroundImageStyles = hasImage
    ? css`
        background: url(${backgroundImageUrls.mobile}) no-repeat
          ${imageResizerService.getBackgroundFocalPoint(
            data.slideImage?.entity.focalPoint
          )};
        ${theme.mq.tablet} {
          background-image: url(${backgroundImageUrls.tablet});
        }
        ${theme.mq.small_desktop} {
          background-image: url(${backgroundImageUrls.small_desktop});
        }
        ${theme.mq.large_desktop} {
          background-image: url(${backgroundImageUrls.large_desktop});
        }
      `
    : null;

  const styles = {
    section: css`
      position: relative;
      background: #000;
      width: 100%;
      ${theme.mq.small_desktop} {
        min-height: 100vh;
        box-shadow: 0px 3px 8px 2px rgb(0 0 0 / 25%);
      }
    `,

    mediaWrapper: css`
      width: 100%;
      ${theme.mq.small_desktop} {
        position: sticky;
        top: 0;
        ${lead ? null : opacity};
      }
      ${lead ? opacity : null};
    `,

    video: css`
      display: ${hasImage && hasVideo ? 'none' : 'block'};
      position: relative;
      width: 100%;
      height: ${lead ? '100vh' : 0};
      padding-top: ${lead ? 0 : '100%'};
      ${theme.mq.small_desktop} {
        display: block;
        height: 100vh;
        padding-top: 0;
      }
    `,
    videoInner: css`
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
      ${theme.mq.small_desktop} {
        position: static;
        height: 100vh;
      }
    `,

    image: css`
      display: block;
      width: 100%;
      height: ${lead ? '100vh' : 0};
      padding-top: ${lead ? 0 : '100%'};
      ${theme.mq.small_desktop} {
        display: ${hasImage && hasVideo ? 'none' : 'block'};
        height: 100vh;
        padding-top: 0;
      }
      ${backgroundImageStyles}
      background-size: cover;
      background-color: #000;
    `
  };

  return (
    <section
      data-vertical-slideshow-item
      css={styles.section}
      style={{
        // We move z-index out of the main style definition so all sections share the same CSS rule.
        // Otherwise, different CSS rules are created for each section.
        zIndex: delta
      }}
    >
      <div css={styles.mediaWrapper} data-vertical-slideshow-media-wrapper>
        {hasVideo && (
          <div css={styles.video}>
            <div css={styles.videoInner}>
              <AmbientPlayer
                size="cover"
                videoEntity={data.slideVideo.entity}
              />
            </div>
          </div>
        )}

        {hasImage && <div css={styles.image} />}
      </div>
      {children}
    </section>
  );
};

VerticalSlideItem.defaultProps = {
  lead: false
};

VerticalSlideItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  delta: PropTypes.number.isRequired,
  lead: PropTypes.bool,
  children: PropTypes.node.isRequired
};
