import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { VerticalSlideShow } from '../../organisms/VerticalSlideShow';
import { VerticalSlideItem } from '../../molecules/VerticalSlideItem';
import { VerticalSlideLeadBox } from '../../molecules/VerticalSlideLeadBox';
import { VerticalSlideContentBox } from '../../molecules/VerticalSlideContentBox';
import { SponsoredBar } from '../../../SponsoredBar';

export const VerticalSlideshowGalleryTemplate = ({ content }) => {
  const containerTag = useRef(null);
  const slidesLength = content.mainContent.length;

  return (
    <div ref={containerTag}>
      <SponsoredBar content={content} articleRef={containerTag} />
      <VerticalSlideShow>
        {content.mainContent.map((data, index) => {
          if (index === 0) {
            return (
              <VerticalSlideItem
                data={data.entity}
                delta={slidesLength - index}
                lead
                key={data.entity.id}
              >
                <VerticalSlideLeadBox data={data.entity} content={content} />
              </VerticalSlideItem>
            );
          }
          return (
            <VerticalSlideItem
              data={data.entity}
              delta={slidesLength - index}
              key={data.entity.id}
            >
              <VerticalSlideContentBox
                data={data.entity}
                slideNumber={index}
                content={content}
              />
            </VerticalSlideItem>
          );
        })}
      </VerticalSlideShow>
    </div>
  );
};

VerticalSlideshowGalleryTemplate.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
